import React from 'react';

const Redirect = ({pageContext}) => {
    const {slug, redirect} = pageContext;

    // internal or external redirect
    if (typeof window !== 'undefined') {
        window.location =
            redirect[0] === '/'
                ? `${window.location.origin}${redirect}`
                : redirect;
    }

    return <div>Being redirected to {redirect}</div>;
};

export default Redirect;
